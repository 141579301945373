var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"certs"}},[_c('b-container',{attrs:{"fluid":""}},[_c('goBack',{attrs:{"target":"DepartmentsCertificates"}}),_c('b-row',[_c('b-col',[(this.CERT_STATE_REPORTED == true)?_c('div',[_c('b-icon',{attrs:{"icon":"exclamation-triangle","variant":"danger","aria-hidden":"true"}}),_vm._v(" This award is reported to the State of Tennessee for CTE Industry Certification Tracking ")],1):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.LANG_VIEWING_CERT)+" "),(_vm.showContents)?_c('b-badge',[_vm._v(_vm._s(_vm.CERT_COUNT))]):_vm._e()],1),(_vm.showContents)?_c('h3',[_vm._v(" Your certificate slots "),_c('b-badge',[_vm._v(_vm._s(_vm.SELF_CERT_COUNT))])],1):_vm._e()]),(_vm.showContents && this.NOT_LOCKED)?_c('b-col',{staticClass:"text-right"},[(
            this.allowed_delegate &&
            this.$parent.ADMINS.includes(this.$parent.USER_INFO.role)
          )?_c('b-button',{staticClass:"mr-1",attrs:{"to":{
            name: 'CertificatePageDelegate',
            params: { cert_id: this.$route.params.cert_id },
          },"variant":"primary"}},[_c('b-icon',{attrs:{"icon":"box-arrow-up","aria-hidden":"true"}}),_vm._v(" Delegate Certificates to Teachers")],1):_vm._e(),(_vm.showContents && this.$parent.USER_INFO.role == 'TEACHER')?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.releaseCert}},[_c('b-icon',{attrs:{"icon":"box-arrow-up","aria-hidden":"true"}}),_vm._v(" Return Certificates to Department Chair")],1):_vm._e(),(
            _vm.showContents &&
            this.CERT_MAX_CHILD >= this.totalItems &&
            this.allowed_edit
          )?_c('b-button',{staticClass:"btn-success btn-lg",attrs:{"variant":"primary","disabled":_vm.DISABLED_ADD,"id":"award-button","to":{
            name: 'CertificatePageCert',
            params: { cert_id: this.$route.params.cert_id },
          }}},[_c('b-icon',{attrs:{"icon":"award","aria-hidden":"true"}}),_vm._v(" Award Certificates to Students")],1):_vm._e(),(this.$parent.USER_INFO.role == 'TEACHER')?_c('b-tooltip',{attrs:{"show":"","target":"award-button"}},[_vm._v("Click here to get started!")]):_vm._e()],1):_vm._e(),(!this.allowed_edit && _vm.showContents)?_c('b-col',{staticClass:"text-right"},[_c('h5',[_vm._v("You are not allowed to edit this certificate")])]):_vm._e()],1)],1),(
      _vm.showContents &&
      this.$parent.ADMINS.includes(this.$parent.USER_INFO.role)
    )?_c('b-table',{attrs:{"empty-html":_vm.DELEGATES_EMTPY_TABLE,"bordered":"","show-empty":"","items":_vm.delegates_items,"fields":_vm.delegates_fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteDelegate(data.item.user_id)}}},[_c('b-icon',{attrs:{"icon":"trash-fill","aria-hidden":"true"}}),_vm._v(" Delete")],1)]}}],null,false,1381916786)}):_vm._e(),(_vm.showContents)?_c('b-table',{staticClass:"table",attrs:{"empty-html":_vm.EMTPY_TABLE,"bordered":"","show-empty":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":0},scopedSlots:_vm._u([{key:"cell(cert_notes)",fn:function(data){return [(data.item.cert_notes)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.noteView(data.item.cert_notes)))]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.notes(
              'view',
              data.item.cert_id,
              data.item.cert_owner_id,
              data.item
            )}}},[_c('b-icon',{attrs:{"icon":"pencil-square","aria-hidden":"true"}}),_vm._v(" View Note")],1)],1):_vm._e(),(!data.item.cert_notes)?_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.notes(
              'add',
              data.item.cert_id,
              data.item.cert_owner_id,
              data.item
            )}}},[_c('b-icon',{attrs:{"icon":"pencil-square","aria-hidden":"true"}}),_vm._v(" Add Note")],1)],1):_vm._e()]}},{key:"cell(student_id)",fn:function(data){return [(_vm.studentIDVisible)?_c('p',[_vm._v(_vm._s(data.item.student_id))]):_vm._e(),(!_vm.studentIDVisible)?_c('p',[_c('i',[_vm._v("Student IDs are not shown")])]):_vm._e()]}},{key:"cell(attempts)",fn:function(data){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showAttempts(data.item.cert_id)}}},[_c('b-icon',{attrs:{"icon":"clipboard","aria-hidden":"true"}}),_vm._v(" Attempts")],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteCert(data.item.cert_id, data.item.cert_owner_id)}}},[_c('b-icon',{attrs:{"icon":"trash-fill","aria-hidden":"true"}}),_vm._v(" Delete")],1)]}},{key:"cell(cert_file)",fn:function(data){return [(data.item.cert_file)?_c('p',[_vm._v("Uploaded")]):_vm._e(),(!data.item.cert_file)?_c('p',[_vm._v("Not Uploaded")]):_vm._e()]}}],null,false,1616021255)}):_vm._e(),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }